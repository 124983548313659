<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-flex flex-row align-center">
        <v-btn text @click="()=>{$router.go(-1)}"><v-icon dark>mdi-arrow-left</v-icon></v-btn>
        <h1>Custom Controllers</h1>
        <v-btn class="ml-2" fab color="info" small @click="openAddControllerDialog"><v-icon>mdi-plus</v-icon></v-btn>
        <v-btn class="ml-2" color="warning" small @click="reloadRoutes">Reload Routes</v-btn>
        <v-btn class="ml-2" color="info" small @click="openLogWindow">See Files</v-btn>
        <v-progress-circular
            indeterminate
            color="green"
            v-if="initialLoader"
            style="margin-left: 10px;"
        ></v-progress-circular>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4">
        <v-text-field label="Search Controllers" v-model="searchControllers" clearable @click:clear="filterControllers" outlined dense @input="filterControllers"/>
        <span>
          <span class="d-flex flex-row align-center mb-2">
            <h3>Routes/Controllers <span v-if="!searchControllers">(drag to reorder)</span></h3>
            <v-btn class="ml-2" color="info" small @click="openAddGroupDialog">New Group</v-btn>
          </span>
          <span v-if="!searchControllers">
            <v-btn block color="success" :loading="orderLoading" class="mb-2" @click="saveControllerOrder">Save Order</v-btn>
            <draggable :list="controllers" v-bind="dragOptions" @start="groupDrag=true" @end="groupOrderChanged">
              <transition-group type="transition" :name="!groupDrag ? 'flip-list' : null">
                <v-card v-for="(group, g) of controllers" :key="g" class="mb-5">
                  <v-card-title class="d-flex flex-row justify-space-between">
                    <span>{{group.name}}</span>
                    <span><span class="green--text">{{group.groupOrder}}</span> | {{g}}</span>
                  </v-card-title>
                  <hr>
                  <draggable style="display: block; min-width: 100%; min-height: 25px;" :list="group.controllers" group="controllers" v-bind="dragOptions" @start="drag=true" @end="orderChanged">
                    <transition-group style="display: block; min-width: 100%; min-height: 25px;" type="transition" :name="!drag ? 'flip-list' : null">
                      <v-card tile flat :color="selectedController?.id===controller.id?'blue-grey darken-1':''" v-for="(controller, i) of group.controllers" :key="i" @click="loadController(controller.id)">
                        <v-card-text :class="`elevation-2 pa-2 mb-1 d-flex justify-space-between`">
                          <span class="">
                            <span class="ml-2">router.<span class="red--text">{{controller.routeType}}</span>("/<span class="blue--text">{{controller.route}}</span>", table.<span class="orange--text">{{controller.name}}</span>)</span>
                            <span class="ml-1" style="font-size: 12px;">{{(controller.contents||"").length}} chars</span>
                          </span>
                          <span><span class="green--text">{{controller.order}}</span> | {{i}}</span>
                        </v-card-text>
                      </v-card>
                    </transition-group>
                  </draggable>
                </v-card>
              </transition-group>
            </draggable>
          </span>

          <span v-if="searchControllers">
            <v-card color="light-blue lighten-5" v-for="(group, g) of controllers" :key="g" class="mb-5">
              <v-card-title>
                <span>{{group.name}}</span>
                <span><span class="green--text">{{group.groupOrder}}</span> | {{g}}</span>
              </v-card-title>
                <v-card-text :class="`${selectedController?.id===controller.id?'black white--text':'white'} elevation-2 pa-2 mb-1 d-flex justify-space-between`" v-for="(controller, i) of group.controllers" :key="i" @click="loadController(controller.id)">
                  <span class="">
                    <span class="ml-2">router.<span class="red--text">{{controller.routeType}}</span>("/<span class="blue--text">{{controller.route}}</span>", table.<span class="orange--text">{{controller.name}}</span>)</span>
                  </span>
                  <span><span class="green--text">{{controller.order}}</span> | {{i}}</span>
                </v-card-text>
            </v-card>
          </span>
        </span>
      </v-col>
      <v-col cols="8">
        <span v-if="selectedController && selectedController.id" class="d-flex flex-column">
          <span class="d-flex flex-row justify-space-between">
            <span class="d-flex flex-row align-center">
              <v-btn color="success" :disabled="!validControllerValues(selectedController)" :loading="saveLoading" @click="saveController">Save</v-btn>
              <v-select class="ml-2" outlined dense hide-details :items="themes" v-model="cmOptions.theme" label="Text Editor Theme"/>
              <v-btn class="ml-2" color="info" :disabled="!validControllerValues(selectedController)" @click="duplicateController">Create A Copy</v-btn>
            </span>
            <span>
              <v-btn class="mr-1" small color="warning" :loading="testLoading" @click="testController">Test</v-btn>
              <v-btn color="error" :loading="deleteLoading" @click="deleteController">Delete</v-btn>
            </span>
          </span>
          <span class="mt-5 mb-2 d-flex flex-row">
            <v-text-field hide-details outlined dense label="Name" v-model="selectedController.name" class="orange"/>
            <v-text-field hide-details class="info ml-1" outlined dense label="Route" v-model="selectedController.route"/>
            <v-select hide-details class="red ml-1" style="max-width: 100px;" outlined dense :items="routeTypes" v-model="selectedController.routeType" label="Route Type"/>
          </span>
          <hr>
          <span class="my-2">
            <span class="d-flex flex-row align-center mb-2">
              <v-btn small color="info" @click="importsGlobal.show=!importsGlobal.show">{{!importsGlobal.show?"Show":"Hide"}} Imports</v-btn>
              <v-btn v-if="importsGlobal.show" class="ml-2" @click="saveImports" color="success" :loading="importsGlobal.loading">Save Imports</v-btn>
            </span>
            <span v-if="importsGlobal.show">
              <codemirror class="CodeMirrorHeightAuto" :options="cmOptions" v-model="importsGlobal.data"/>
            </span>
          </span>
          <hr>
          <span class="my-2">
            <codemirror class="CodeMirrorHeightAuto" :options="{...cmOptions, theme: 'dracula', viewportMargin: Infinity, readOnly: true}" :value="selectedControllerPrefix"/>
            <codemirror class="CodeMirrorHeightAuto" :options="{...cmOptions, firstLineNumber: 2}" v-model="selectedController.contents"/>
            <codemirror class="CodeMirrorHeightAuto" :options="{...cmOptions, firstLineNumber:  (selectedController.contents||'').split(/\r\n|\r|\n/).length+2, theme: 'dracula', viewportMargin: Infinity, readOnly: true}" :value="selectedControllerSuffix"/>
          </span>
        </span>
        <span v-if="selectedController && !selectedController.id" class="d-flex flex-row align-center justify-center">
          <v-progress-circular indeterminate color="info"/>
        </span>
      </v-col>
    </v-row>

    <v-dialog v-model="addControllerDialog.isOpen" width="600">
      <v-card>
        <v-card-title>Add New Controller {{addControllerDialog.isDuplicate?`As Duplicate from ${selectedController.name}`:''}}</v-card-title>
        <v-card-text>
          <span class="d-flex flex-row">
            <v-autocomplete :items="controllers" item-value="name" item-text="name" outlined label="Group" v-model="addControllerDialog.data.group"/>
            <v-btn fab color="info" class="mt-2 ml-1" @click="openAddGroupDialog" small><v-icon>mdi-plus</v-icon></v-btn>
          </span>
          <v-text-field outlined label="Route" v-model="addControllerDialog.data.route"/>
          <v-select outlined label="Route Type" :items="routeTypes" v-model="addControllerDialog.data.routeType"/>
        </v-card-text>
        <v-card-actions class="pl-5 pb-5">
          <v-btn text @click="closeAddControllerDialog" color="error">Cancel</v-btn>
          <v-btn @click="addController" :disabled="!validControllerValues(addControllerDialog.data)" :loading="addControllerDialog.loading" color="success">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addGroupDialog.isOpen" width="650">
      <v-card>
        <v-card-title>Add New Group</v-card-title>
        <v-card-text>
          <v-text-field outlined label="Name" v-model="addGroupDialog.data.name"/>
        </v-card-text>
        <v-card-actions class="pl-5 pb-5">
          <v-btn text @click="closeAddGroupDialog" color="error">Cancel</v-btn>
          <v-btn @click="addGroup" :disabled="!addGroupDialog.data.name" :loading="addGroupDialog.loading" color="success">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="logWindow.isOpen" width="90%">
      <v-card style="overflow-y:hidden;">
        <v-card-title class="d-flex flex-row justify-space-between">
          <span>
            <span>Custom Files</span>
            <v-btn small color="teal lighten-3" class="ml-2" @click="toggleLogFile">Toggle Logs File</v-btn>
            <v-btn small color="teal lighten-3" class="ml-2" @click="toggleControllerFile">Toggle Controllers File</v-btn>
            <v-btn small color="teal lighten-3" class="ml-2" @click="toggleRouteFile">Toggle Routes File</v-btn>
          </span>
          <span><v-btn fab small text @click="closeLogWindow"><v-icon>mdi-close</v-icon></v-btn></span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-if="logWindow.log.visible">
              <v-card outlined height="80vh">
                <v-card-title>
                  <span>Log File</span>
                  <v-btn color="info" x-small fab class="ml-2" @click="getLog" :loading="logWindow.log.loading"><v-icon>mdi-refresh</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                  <v-progress-circular v-if="logWindow.log.loading" indeterminate color="info"/>
                  <codemirror v-else class="CodeMirrorScrollable" :options="{...cmOptions, mode: 'text/plain', lineWrapping: true, theme: 'dracula', viewportMargin: '10', readOnly: true}" v-model="logWindow.log.data"/>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col v-if="logWindow.controllers.visible">
              <v-card outlined height="80vh">
                <v-card-title>
                  <span>Controllers File</span>
                  <v-btn color="info" x-small fab class="ml-2" @click="getControllerFile" :loading="logWindow.controllers.loading"><v-icon>mdi-refresh</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                  <v-progress-circular v-if="logWindow.controllers.loading" indeterminate color="info"/>
                  <codemirror v-else class="CodeMirrorScrollable" :options="{...cmOptions, mode: 'text/javascript', lineWrapping: true, viewportMargin: '10', readOnly: true}" v-model="logWindow.controllers.data"/>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col v-if="logWindow.routes.visible">
              <v-card outlined height="80vh">
                <v-card-title>
                  <span>Routes File</span>
                  <v-btn color="info" x-small fab class="ml-2" @click="getRouteFile" :loading="logWindow.routes.loading"><v-icon>mdi-refresh</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                  <v-progress-circular v-if="logWindow.routes.loading" indeterminate color="info"/>
                  <codemirror v-else class="CodeMirrorScrollable" :options="{...cmOptions, mode: 'text/javascript', lineWrapping: true, viewportMargin: '10', readOnly: true}" v-model="logWindow.routes.data"/>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<style>
.CodeMirrorHeightAuto *{
  height: auto;
}

.CodeMirrorScrollable .CodeMirror{
  height: 75vh;
}

.CodeMirrorScrollable .CodeMirror-vscrollbar{
  overflow-y: scroll;
}
</style>

<script>

import { mapGetters } from 'vuex'
import axios from 'axios';
import draggable from "vuedraggable";
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/theme/3024-day.css'
import 'codemirror/theme/3024-night.css'
import 'codemirror/theme/abbott.css'
import 'codemirror/theme/abcdef.css'
import 'codemirror/theme/ambiance-mobile.css'
import 'codemirror/theme/ambiance.css'
import 'codemirror/theme/ayu-dark.css'
import 'codemirror/theme/ayu-mirage.css'
import 'codemirror/theme/base16-dark.css'
import 'codemirror/theme/base16-light.css'
import 'codemirror/theme/bespin.css'
import 'codemirror/theme/blackboard.css'
import 'codemirror/theme/cobalt.css'
import 'codemirror/theme/colorforth.css'
import 'codemirror/theme/darcula.css'
import 'codemirror/theme/dracula.css'
import 'codemirror/theme/duotone-dark.css'
import 'codemirror/theme/duotone-light.css'
import 'codemirror/theme/eclipse.css'
import 'codemirror/theme/elegant.css'
import 'codemirror/theme/erlang-dark.css'
import 'codemirror/theme/gruvbox-dark.css'
import 'codemirror/theme/hopscotch.css'
import 'codemirror/theme/icecoder.css'
import 'codemirror/theme/idea.css'
import 'codemirror/theme/isotope.css'
import 'codemirror/theme/juejin.css'
import 'codemirror/theme/lesser-dark.css'
import 'codemirror/theme/liquibyte.css'
import 'codemirror/theme/lucario.css'
import 'codemirror/theme/material-darker.css'
import 'codemirror/theme/material-ocean.css'
import 'codemirror/theme/material-palenight.css'
import 'codemirror/theme/material.css'
import 'codemirror/theme/mbo.css'
import 'codemirror/theme/mdn-like.css'
import 'codemirror/theme/midnight.css'
import 'codemirror/theme/monokai.css'
import 'codemirror/theme/moxer.css'
import 'codemirror/theme/neat.css'
import 'codemirror/theme/neo.css'
import 'codemirror/theme/night.css'
import 'codemirror/theme/nord.css'
import 'codemirror/theme/oceanic-next.css'
import 'codemirror/theme/panda-syntax.css'
import 'codemirror/theme/paraiso-dark.css'
import 'codemirror/theme/paraiso-light.css'
import 'codemirror/theme/pastel-on-dark.css'
import 'codemirror/theme/railscasts.css'
import 'codemirror/theme/rubyblue.css'
import 'codemirror/theme/seti.css'
import 'codemirror/theme/shadowfox.css'
import 'codemirror/theme/solarized.css'
import 'codemirror/theme/ssms.css'
import 'codemirror/theme/the-matrix.css'
import 'codemirror/theme/tomorrow-night-bright.css'
import 'codemirror/theme/tomorrow-night-eighties.css'
import 'codemirror/theme/ttcn.css'
import 'codemirror/theme/twilight.css'
import 'codemirror/theme/vibrant-ink.css'
import 'codemirror/theme/xq-dark.css'
import 'codemirror/theme/xq-light.css'
import 'codemirror/theme/yeti.css'
import 'codemirror/theme/yonce.css'
import 'codemirror/theme/zenburn.css'

export default {
  name: 'Home',
  components: {
    draggable
  },
  data () {
    return {
      initialLoader: true,
      snackObj: {
        state: false,
        color: '',
        text: ''
      },
      addControllerDialog: {
        isOpen: false,
        loading: false,
        data: {
          routeType: "get"
        },
        isDuplicate: false
      },

      addGroupDialog: {
        isOpen: false,
        loading: false,
        data: {
          name: ""
        }
      },

      logWindow: {
        isOpen: false,
        log: {
          visible: true,
          data: "",
          loading: false
        },
        controllers: {
          visible: false,
          data: "",
          loading: false
        },
        routes: {
          visible: false,
          data: "",
          loading: false
        }
      },

      controllers: [],
      selectedController: null,

      filteredControllers: [],
      searchControllers: "",

      saveLoading: false,
      testLoading: false,
      deleteLoading: false,
      orderLoading: false,

      importsGlobal: {
        show: false,
        loading: false,
        data: ""
      },

      themes: ["3024-day","3024-night","abbott","abcdef","ambiance-mobile","ambiance","ayu-dark","ayu-mirage","base16-dark","base16-light","bespin","blackboard","cobalt","colorforth","darcula","dracula","duotone-dark","duotone-light","eclipse","elegant","erlang-dark","gruvbox-dark","hopscotch","icecoder","idea","isotope","juejin","lesser-dark","liquibyte","lucario","material-darker","material-ocean","material-palenight","material","mbo","mdn-like","midnight","monokai","moxer","neat","neo","night","nord","oceanic-next","panda-syntax","paraiso-dark","paraiso-light","pastel-on-dark","railscasts","rubyblue","seti","shadowfox","solarized","ssms","the-matrix","tomorrow-night-bright","tomorrow-night-eighties","ttcn","twilight","vibrant-ink","xq-dark","xq-light","yeti","yonce","zenburn"],

      cmOptions: {
        tabSize: 4,
        mode: 'text/javascript',
        theme: 'base16-dark',
        lineNumbers: true,
        line: true
      },
      groupDrag: true,
      drag: true,

      routeTypes: ["get", "post", "put", "delete"]
    }
  },
  computed: {
    ...mapGetters(['getUsername', 'getEndpoint']),
    dragOptions() {
      return {
        animation: 200
      };
    },
    selectedControllerPrefix(){
      return `exports.${this.selectedController.name} = async (req, res, next) => {`
    },
    selectedControllerSuffix(){
      return `}`
    }
  },
  async mounted(){
    try {
      await this.getAllData()
    } catch (error) {
      console.error(error)
      this.snack(error.msg || error.msg?.message || error, "error");
    }finally {
      this.initialLoader = false;
    }
  },
  methods: {
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    },
    validControllerValues(obj){
      if(!obj) return false;
      if(!obj.route) return false;
      if(!obj.routeType) return false;

      if(obj.route.includes(" ")) return false;

      return true;
    },
    async getAllData(){
      try{
        this.initialLoader = true;
        let res = await axios.get(`${this.getEndpoint}/api/customControllers/groupByGroup`)
        if(res.data.error) throw res.data.error
        this.controllers = res.data.data
        this.filteredControllers = this.controllers;

        res = await axios.get(`${this.getEndpoint}/api/globals/customControllerImports`)
        if(res.data.error) throw res.data.error
        this.importsGlobal.data = res.data.data?.value||""

        this.generateOrder()
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }finally {
        this.initialLoader = false;
      }
    },
    openAddControllerDialog(){
      this.addControllerDialog.isOpen = true;
    },
    closeAddControllerDialog(){
      this.addControllerDialog.data = {routeType: "get"};
      this.addControllerDialog.isOpen = false;
      this.addControllerDialog.isDuplicate = false;
    },
    filterControllers(){
      if(!this.searchControllers || !(this.searchControllers.trim())){
        this.filteredControllers = this.controllers;
        return;
      }

      this.filteredControllers = this.controllers.filter(x => x.name.toLowerCase().includes(this.searchControllers.toLowerCase()) || x.route.toLowerCase().includes(this.searchControllers.toLowerCase()))
    },
    async addController(){
      try{
        if(this.addControllerDialog.isDuplicate && !this.selectedController){
          this.closeAddControllerDialog();
          throw "No controller is selected to duplicate."
        }

        let obj = {
          ...this.addControllerDialog.data, id: null
        }

        if(this.addControllerDialog.isDuplicate){
          obj = {
            ...this.selectedController,
            ...obj,

          }
          delete obj.createdAt
          delete obj.updatedAt
        }

        obj.name = obj.route;

        this.addControllerDialog.loading = true;
        let res = await axios.post(`${this.getEndpoint}/api/customControllers/`, obj)
        if(res.data.error) throw res.data.error

        this.closeAddControllerDialog();

        await this.getAllData()

        this.selectedController = res.data.data

        this.snack("Controller created", "success")
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }finally {
        this.addControllerDialog.loading = false;
      }
    },
    async duplicateController(){
      try{
        this.addControllerDialog.isDuplicate = true;
        this.openAddControllerDialog();
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }finally {
        this.addControllerDialog.loading = false;
      }
    },
    async saveController(){
      try{
        this.saveLoading = true;
        let res = await axios.put(`${this.getEndpoint}/api/customControllers/${this.selectedController.id}`, this.selectedController)
        if(res.data.error) throw res.data.error

        this.selectedController = res.data.data;

        await this.getAllData()

        this.snack("Controller updated", "success")
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }finally {
        this.saveLoading = false;
      }
    },
    async deleteController(){
      try{
        this.deleteLoading = true;

        let verified = confirm("Are you sure you want to delete this controller?");
        if(!verified) return;

        let res = await axios.delete(`${this.getEndpoint}/api/customControllers/${this.selectedController.id}`)
        if(res.data.error) throw res.data.error

        this.selectedController = null
        await this.getAllData()

        this.snack("Controller deleted", "success")
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }finally {
        this.deleteLoading = false;
      }
    },
    async saveControllerOrder(){
      try{
        this.orderLoading = true;

        let cleansed = this.controllers.map(g => {
          return {
            ...g,
            controllers: g.controllers.map(c => {
              return {
                id: c.id,
                group: c.group,
                order: c.order,
                groupOrder: c.groupOrder
              }
            })
          }
        });

        let res = await axios.put(`${this.getEndpoint}/api/customControllers/saveOrder`, cleansed)
        if(res.data.error) throw res.data.error

        await this.getAllData()

        this.snack("Order updated", "success")
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }finally {
        this.orderLoading = false;
      }
    },
    openAddGroupDialog(){
      this.addGroupDialog.isOpen = true;
    },
    closeAddGroupDialog(){
      this.addGroupDialog.data = {name: ""};
      this.addGroupDialog.isOpen = false;
    },
    addGroup(){
      this.controllers.push({name: this.addGroupDialog.data.name, controllers: []})

      this.closeAddGroupDialog();
    },
    groupOrderChanged(){
      this.groupDrag=false;

      this.generateOrder();
    },
    orderChanged(){
      this.drag=false;

      this.generateOrder();
    },
    generateOrder(){
      let count = 0;
      for(let i=0;i<this.controllers.length;i++){
        this.controllers[i].groupOrder = i;

        for(let controller of this.controllers[i].controllers){
          controller.order = count;
          controller.group = this.controllers[i].name||""
          controller.groupOrder = i;
          count++;
        }
      }
    },
    async loadController(id){
      try{
        this.selectedController = {
          contents: ""
        };

        let res = await axios.get(`${this.getEndpoint}/api/customControllers/${id}`)
        if(res.data.error) throw res.data.error

        let con = res.data.data;

        if(!con.metadata){
          con.metadata = {};
        }

        this.selectedController = con;

        let found;
        for(let g of this.controllers){
          let quickExit = false;
          for(let c of g.controllers){
            if(c.id===id){
              found = c;
              quickExit = true;
              break;
            }
            if(quickExit) break;
          }
        }
        if(found) this.selectedController.group = found.group;

        this.snack("Controller loaded")
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
    },
    async saveImports(){
      try{
        this.importsGlobal.loading = true;
        let res = await axios.put(`${this.getEndpoint}/api/globals/customControllerImports`, {value: this.importsGlobal.data})
        if(res.data.error) throw res.data.error

        await this.getAllData()

        this.snack("Imports updated", "success")
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }finally {
        this.importsGlobal.loading = false;
      }
    },
    async testController(){
      try{
        this.testLoading = true
        let res;
        if(this.selectedController.routeType==='get' || this.selectedController.routeType==='delete')
          res = await axios[this.selectedController.routeType](`${this.getEndpoint}/api/custom/${this.selectedController.route}`)

        if(res.data.error) throw res.data.error
        console.log(`START OF Custom Test [${this.selectedController.name}] Response: `)
        console.log(res.data)
        console.log(`END OF Custom Test [${this.selectedController.name}] Response`)

        this.snack("Controller tested. See Console Log", "success")
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }finally {
        this.testLoading = false
      }
    },
    async reloadRoutes(){
      try{
        this.testLoading = true
        let res = await axios.put(`${this.getEndpoint}/api/customControllers/reloadRoutes`)
        if(res.data.error) throw res.data.error
        console.log("Reloading Routes", res.data)

        this.snack("Routes reloading. Check log to validate.", "warning")
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }finally {
        this.testLoading = false
      }
    },
    openLogWindow(){
      this.logWindow.isOpen = true;

      if(this.logWindow.log.visible) this.getLog();
      if(this.logWindow.controllers.visible) this.getControllerFile();
      if(this.logWindow.routes.visible) this.getRouteFile();
    },
    closeLogWindow(){
      this.logWindow.isOpen = false;
    },
    toggleLogFile(){
      this.logWindow.log.visible=!this.logWindow.log.visible
      if(this.logWindow.log.visible){
        this.getLog()
      }
    },
    async getLog(){
      try{
        this.logWindow.log.loading = true
        let res = await axios.get(`${this.getEndpoint}/api/customControllers/getLogFile`)
        if(res.data.error) throw res.data.error
        this.logWindow.log.data = res.data.data;

        this.snack("Log Loaded.", "success")
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }finally {
        this.logWindow.log.loading = false
      }
    },
    toggleControllerFile(){
      this.logWindow.controllers.visible=!this.logWindow.controllers.visible
      if(this.logWindow.controllers.visible){
        this.getControllerFile()
      }
    },
    async getControllerFile(){
      try{
        this.logWindow.controllers.loading = true
        let res = await axios.get(`${this.getEndpoint}/api/customControllers/getControllerFile`)
        if(res.data.error) throw res.data.error
        this.logWindow.controllers.data = res.data.data;

        this.snack("Controllers Loaded.", "success")
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }finally {
        this.logWindow.controllers.loading = false
      }
    },
    toggleRouteFile(){
      this.logWindow.routes.visible=!this.logWindow.routes.visible
      if(this.logWindow.routes.visible){
        this.getRouteFile()
      }
    },
    async getRouteFile(){
      try{
        this.logWindow.routes.loading = true
        let res = await axios.get(`${this.getEndpoint}/api/customControllers/getRouteFile`)
        if(res.data.error) throw res.data.error
        this.logWindow.routes.data = res.data.data;

        this.snack("Routes Loaded.", "success")
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }finally {
        this.logWindow.routes.loading = false
      }
    }
  }
}
</script>
